import App from '../../src/App.vue'
import planejamento from './planejamento'
import atividades from './atividades'
import integracoes from './integracoes'
import farmManagement from './farms'
import userManagement from './users'
import catalogModule from '@/modules/catalog'
import plansModule from '@/modules/plans'
import plotModule from '@/modules/plot'
import authModule from '@/modules/auth'
import performanceModule from '@/modules/performance'
import notesModule from '@/modules/notes'
import comparisonModule from '@/modules/comparison'
import reportsModule from '@/modules/reports'
import homeModule from '@/modules/home'
import paymentModule from '@/modules/payment'

const privateRoutes = [
  {
    path: '/',
    component: App,
    meta: {
      requiresAuth: true,
    },
    children: [
      ...planejamento,
      ...atividades,
      ...integracoes,
      ...farmManagement,
      ...userManagement,
      ...catalogModule.router,
      ...plansModule.router,
      ...plotModule.router,
      ...performanceModule.router,
      ...notesModule.router,
      ...comparisonModule.router,
      ...homeModule.router,
      ...paymentModule.router,
    ],
  },
  ...reportsModule.router,
]

const publicRoutes = [...authModule.router]

export { privateRoutes, publicRoutes }
