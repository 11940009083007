const Users = () => import(/* webpackChunkName: "users-page" */ '@/pages/users')

const routes = [
  {
    path: '/users',
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
