const Sowings = () =>
  import(/* webpackChunkName: "activities-module" */ '@/pages/semeaduras')
const Execution = () =>
  import(/* webpackChunkName: "activities-module" */ '@/pages/execucao')

const routes = [
  {
    name: 'sowings',
    path: '/sowings',
    component: Sowings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/execucao',
    component: Execution,
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
