import Vuetify from 'vuetify/lib'

export default Vuetify

export const instantiateVuetify = ({ i18n }) =>
  new Vuetify({
    lang: {
      t: (key, ...params) => i18n.t(key, params),
    },
  })
