const Varieties = () =>
  import(
    /* webpackChunkName: "planning-module" */ '@/pages/cultivares/VarietyHome.vue'
  )
const Programs = () =>
  import(
    /* webpackChunkName: "planning-module" */ '@/pages/programas/DiseaseHome.vue'
  )

const routes = [
  {
    name: 'varieties',
    path: '/cultivares',
    component: Varieties,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'programs',
    path: '/programas',
    component: Programs,
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
