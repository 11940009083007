<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      type="list-item, divider, list-item, divider, list-item, divider,list-item, divider, list-item, divider, list-item, divider"
      class="mx-auto"
    >
    </v-skeleton-loader>

    <v-list v-else max-height="600" class="ndvi-date-list">
      <v-subheader>{{ $t('ndvi.dates_for_analysis') }}</v-subheader>
      <v-list-item v-if="!convertDates || !convertDates.length" class="caption">
        {{ $t('ndvi.list.no_content') }}
      </v-list-item>

      <v-list-item-group v-model="selectedItem" color="primary">
        <v-list-item
          v-for="(date, indexDate) in convertDates"
          :key="indexDate"
          @click="selectTabByClick(indexDate)"
        >
          <v-list-item-content>
            <v-list-item-title
              class="d-flex align-center"
              :title="`${date.type} - ${date.cloudCovering}%`"
            >
              {{ date.date }}
              <v-icon
                class="ml-1"
                v-if="date.cloudCovering > 0"
                :title="`${date.cloudCovering}%`"
              >
                mdi-cloud-outline
              </v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    images: { type: Array, required: true },
    loading: { type: Boolean, required: true },
  },
  data() {
    return {
      selectedItem: null,
    }
  },
  watch: {
    images(newDates) {
      if (newDates?.length) {
        this.selectedItem = 0
        this.selectTab(0)
      }
    },
  },
  methods: {
    selectTab(indexDate) {
      const propsData = this.images[indexDate]?.images
      this.$emit('date-selected', propsData)
    },
    selectTabByClick(indexDate) {
      this.selectTab(indexDate)
      this.$emit('log-choose-date')
    },
  },
  computed: {
    convertDates() {
      const formattedData = this.images.map((item) => {
        return {
          ...item,
          date: item.date.formatDate(),
        }
      })
      return formattedData
    },
  },
}
</script>

<style lang="scss" scoped>
.ndvi-date-list {
  overflow-y: auto;
}
</style>
