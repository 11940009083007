<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="attributes.dates"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        :label="$t('select_period')"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="attributes.dates"
      range
      no-title
      scrollable
      :locale="getLocale"
      :min="minDate"
      :max="maxDate"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        {{ $t('cancelar') }}
      </v-btn>
      <v-btn text color="primary" :disabled="disableSave" @click="confirmDates">
        {{ $t('select') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment'
import { currentLocale } from '@/language'

export default {
  props: {
    talhaoId: { required: true, type: Number },
  },

  data() {
    return {
      attributes: {
        dates: [],
        plot_id: null,
      },
      menu: false,
    }
  },
  mounted() {
    this.initializeDates()
  },
  computed: {
    getLocale() {
      return currentLocale()
    },

    formattedDate() {
      return this.attributes?.dates?.length === 2
        ? `${this.attributes?.dates[0].formatDate()} - ${this.attributes?.dates[1].formatDate()}`
        : ''
    },
    disableSave() {
      return this.attributes?.dates?.length < 2
    },
    minDate() {
      return moment().subtract(499, 'days').format('YYYY-MM-DD')
    },
    maxDate() {
      return moment().format('YYYY-MM-DD')
    },
  },

  watch: {
    'attributes.dates'(newRangeSelected) {
      this.fixDates(newRangeSelected)
    },
  },

  methods: {
    emitChangedDates() {
      this.$emit('change', this.attributes)
    },
    confirmDates() {
      this.$refs.menu.save(this.attributes.dates)
      this.emitChangedDates()
      this.logDates()
    },
    logDates() {
      this.$emit('log-range-date', {
        initialDate: this.attributes.dates[0],
        endDate: this.attributes.dates[1],
        totalOfDays: moment(this.attributes.dates[1]).diff(
          moment(this.attributes.dates[0]),
          'days'
        ),
      })
    },
    fixDates(newRangeSelected) {
      if (newRangeSelected?.length === 2) {
        if (moment(newRangeSelected[0]).isAfter(moment(newRangeSelected[1]))) {
          const auxDate = this.attributes.dates[0]
          this.attributes.dates[0] = this.attributes.dates[1]
          this.attributes.dates[1] = auxDate
        }
      }
    },
    initializeDates() {
      this.attributes.plot_id = this.talhaoId
      this.attributes.dates = [
        moment().subtract(30, 'days').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
      ]
      this.emitChangedDates()
    },
  },
}
</script>
