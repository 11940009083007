<script>
import { validationMixin } from 'vuelidate'
import { events, logEvent } from '@/services/analytics'
import { required } from 'vuelidate/lib/validators'
import { convertToTargetUnit } from '@/components/Form/converter'
import NavbarMap from './NavbarMap'
import ModalData from './ModalData'
import Sidebar from './Sidebar'

export default {
  components: {
    Sidebar,
    NavbarMap,
    ModalData,
  },
  data() {
    return {
      dialog: false,
      talhao: {
        id: null,
        nome: null,
        area: null,
        latitude: null,
        longitude: null,
        coordenadas_lat: null,
        coordenadas_lng: null,
      },
      imagesByDate: [],
      tabs: [],
      loadingDates: true,
    }
  },

  mixins: [validationMixin],

  validations: {
    talhao: {
      nome: { required },
    },
  },

  computed: {
    emptyImages() {
      return !this.imagesByDate?.length
    },
    bounds() {
      let lat = this.talhao.coordenadas_lat.split('|')
      let lng = this.talhao.coordenadas_lng.split('|')
      let polygon = []

      for (let x = 0; x < lat.length; x++) {
        polygon.push([parseFloat(lat[x]), parseFloat(lng[x])])
      }

      return polygon
    },
    area() {
      return convertToTargetUnit(this.talhao.area, 'area')
    },
  },

  methods: {
    async fetchImages(ndviData) {
      this.loadingDates = true
      if (ndviData.dates.length == 2) {
        try {
          const { data } = await this.$api.ndvi.searchImages(ndviData)
          this.imagesByDate = this.orderByDescDates(data)
          this.loadingDates = false
        } catch (e) {
          const { message } = e.response.data
          this.$root.$emit('notify', 'error', message)
          this.loadingDates = false
        }
      }
    },
    orderByDescDates(data) {
      return data.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
    },
    logChooseDate() {
      logEvent(events.ndvi.chooseDate, {
        plot_id: this.talhao.id,
      })
    },
    logChangeLayer(key) {
      logEvent(events.ndvi.changeLayer, {
        plot_id: this.talhao.id,
        layer: key,
      })
    },
    logRangeDate(dates) {
      const { initialDate, endDate, totalOfDays } = dates
      logEvent(events.ndvi.changePeriod, {
        plot_id: this.talhao.id,
        initialDate,
        endDate,
        totalOfDays,
      })
    },
    setTabs(tabs) {
      this.tabs = Object.entries(tabs).map(([key, value]) => ({ key, value }))
    },
    // accessed by refs
    // eslint-disable-next-line vue/no-unused-properties
    open(talhao) {
      this.dialog = true
      this.talhao = talhao
      this.talhao.latitude = parseFloat(talhao.latitude)
      this.talhao.longitude = parseFloat(talhao.longitude)
      this.talhao.coordenadas_lat = talhao.coordenadas_lat
      this.talhao.coordenadas_lng = talhao.coordenadas_lng
    },
    close() {
      this.dialog = false
      this.imagesByDate = []
      this.tabs = []
    },
  },
}
</script>

<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="dialog">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>NDVI {{ talhao.nome }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <div class="mt-4 mr-2" v-if="talhao.area > 0">
              {{ $t('area') }}: {{ area }}
              {{ $unitMeasures['area'][$currentLanguage()] }}
            </div>
          </v-toolbar-items>
        </v-toolbar>

        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2" class="pt-9">
            <modal-data
              :talhao-id="talhao.id"
              @change="fetchImages"
              @log-range-date="logRangeDate"
            ></modal-data>
            <sidebar
              :images="imagesByDate"
              @date-selected="setTabs"
              :loading="loadingDates"
              @log-choose-date="logChooseDate"
            ></sidebar>
          </v-col>

          <v-col cols="8">
            <navbar-map
              v-if="tabs.length"
              :empty-images="emptyImages"
              :tab-items="tabs"
              :longitude="talhao.longitude"
              :latitude="talhao.latitude"
              :bounds="bounds"
              @log-change-layer="logChangeLayer"
            ></navbar-map>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
