<template>
  <v-container class="position-relative">
    <v-overlay v-if="emptyImages" :absolute="true" :z-index="99999">
      <v-btn color="white" disabled>
        {{ $t('select_another_period') }}
      </v-btn>
    </v-overlay>
    <v-tabs v-model="tab" background-color="transparent" grow>
      <v-tab
        v-for="(item, index) in tabItems"
        :key="index"
        @change="logClick(item)"
      >
        {{ item.key.toUpperCase() }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, index) in tabItems" :key="index">
        <v-card flat>
          <ndvi-map
            :image-url="item.value"
            :latitude="latitude"
            :longitude="longitude"
            :bounds="bounds"
          >
          </ndvi-map>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-container>
      <v-row>
        <v-col class="ndvi_subtitle">
          <div class="level_label">{{ $t('ndvi.level_label') }}</div>
          <div class="ndvi_description">
            <div><span class="circle red"></span> {{ $t('ndvi.low') }}</div>
            <div>
              <span class="circle yellow"></span> {{ $t('ndvi.medium') }}
            </div>
            <div><span class="circle green"></span> {{ $t('ndvi.high') }}</div>
          </div>
        </v-col>
        <v-col class="know_more">
          <a
            href="https://www.digifarmz.com/blog/imagens-ndvi-fundamentos-e-importancia-para-a-agricultura/"
            target="_blank"
          >
            <span>{{ $t('ndvi.know_more') }}</span>
            <v-icon small>mdi-open-in-new</v-icon>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import NdviMap from './NDVIMap'

export default {
  components: { NdviMap },
  props: {
    emptyImages: {
      type: Boolean,
      required: true,
    },
    tabItems: {
      type: Array,
      required: true,
    },
    longitude: { type: Number, required: true },
    latitude: { type: Number, required: true },
    bounds: { type: Array, required: true },
  },
  data() {
    return {
      tab: 2,
    }
  },
  methods: {
    logClick(item) {
      this.$emit('log-change-layer', item.key)
    },
  },
}
</script>

<style lang="scss" scoped>
.position-relative {
  position: relative;
}

.ndvi_subtitle {
  display: flex;
  align-items: center;
}

.level_label {
  font-weight: 700;
  font-size: 14px;
  color: #3d3d3c;
}

.circle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  display: inline-block;

  red {
    background-color: #ff0000 !important;
  }

  yellow {
    background-color: #fff000 !important;
  }

  green {
    background-color: #00e600 !important;
  }
}

.ndvi_description {
  color: #767676;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 4px;

  & > div {
    margin: 0 4px;
  }
}

.know_more {
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-end;

  a {
    color: #8bc34a !important;
    display: inline-table;
    font-size: 14px !important;
    font-weight: 700 !important;

    span {
      color: #8bc34a !important;
      text-decoration: underline;
    }

    i {
      margin-left: 5px;
      color: #8bc34a !important;
    }
  }
}
</style>
