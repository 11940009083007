const Farms = () =>
  import(/* webpackChunkName: "farms-page" */ '@/pages/fazendas')

const routes = [
  {
    path: '/farms',
    component: Farms,
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
