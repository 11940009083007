const Integrations = () =>
  import(/* webpackChunkName: "integrations-page" */ '@/pages/integrations')

const routes = [
  {
    path: '/integracoes/fieldview',
    component: Integrations,
    meta: {
      requiresAuth: true,
    },
    alias: '/integracoes',
  },
]

export default routes
